import React, { useState, useContext } from 'react'
import localStorage from 'store2'
import { getUnixTime } from 'date-fns'

import { DexieCtx } from 'components'

import debug from 'debug'
const log = debug('@ikew:components:AppStore')

const AppStoreCtx: any = React.createContext(null)

const idKey = 'storeId'

export const AppStoreProvider = (props: any) => {
  const { children } = props
  const [storeId, setStoreId] = useState<string | null>(null)

  const db: any = useContext(DexieCtx)

  // migrate old key
  localStorage.transact(idKey, (curr: any) => {
    const oldKey = 'deliverdis-store'
    if (localStorage.has(oldKey)) {
      curr = localStorage.get(oldKey)
      return curr
    }
  })

  const defaultInterval = 259200 // days in seconds
  localStorage.transact('gcInterval', () => {}, defaultInterval)
  localStorage.transact(
    'gcLast',
    (lastGC: number) => {
      const now = getUnixTime(new Date())
      const intervalKey = 'gcInterval'
      const interval = localStorage.get(intervalKey)
      if (typeof interval !== 'number') {
        return now
      }
      const doGC = now - lastGC > interval
      if (doGC && db) {
        log('GC started')
        db.slots
          .filter((o: any) => o.cleared === 1)
          .delete()
          .then(() => {
            log('GC completed')
          })
        return now
      }
    },
    getUnixTime(new Date()),
  )

  const sid = localStorage.get(idKey)
  const saveStoreId = (storeId: string) => {
    setStoreId(storeId)
    localStorage.set(idKey, storeId, true)
  }

  const value = { storeId: storeId || sid, saveStoreId }

  return <AppStoreCtx.Provider value={value}>{children}</AppStoreCtx.Provider>
}

export default AppStoreCtx
