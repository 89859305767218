import axios from 'axios'

import debug from 'debug'
const log = debug('@ikew:services:HubService')

export default class HubService {
  hub: string
  store: string

  constructor(storeId: string) {
    this.hub = process.env.REACT_APP_HUB_URI || ''
    this.store = storeId

    if (!this.store) {
      log('Store is not set')
    }
  }

  async getStore(): Promise<any> {
    log('Get Store')
    const uri = `${process.env.REACT_APP_HUB_URI}/store/${this.store}`
    return axios.get(uri)
  }

  async trigger(command: string, data: any): Promise<any> {
    log(`Triggering ${command}`)
    const uri = `${process.env.REACT_APP_HUB_URI}/manage/${this.store}/${command}`
    return axios.post(uri, data)
  }
}
