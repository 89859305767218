import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useQueryParam, StringParam } from 'use-query-params'

import { Button, Paper, Typography, TextField } from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'

import { AppStoreCtx, Page } from 'components'

import debug from 'debug'
const log = debug('@ikew:views:SetStore')

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    },
    login: {
      padding: theme.spacing(3),
      width: '25%',
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    input: {
      marginBottom: theme.spacing(1),
    },
  }),
)

const Auth = () => {
  const css = useStyles()
  const { storeId, saveStoreId } = useContext(AppStoreCtx)

  const [storeInput, setStoreInput] = useState<string>('')
  const [redirect, setRedirect] = useState<boolean>(false)

  const onSubmit = useCallback(() => {
    saveStoreId(storeInput)
    setRedirect(true)
  }, [saveStoreId, storeInput])

  // handle setting store id via query string
  const [storeParam] = useQueryParam('store', StringParam)
  useEffect(() => {
    if (redirect) {
      return
    }
    if (storeParam && storeParam !== '') {
      log('Setting store via query string')
      saveStoreId(storeParam)
    }
  }, [onSubmit, redirect, storeParam, saveStoreId])

  useEffect(() => {
    if (storeId === storeParam) {
      setRedirect(true)
    }
  }, [storeId, storeParam])

  // existing store Id
  useEffect(() => {
    if (storeId && storeId !== '') {
      log('Using already set store')
      setStoreInput(storeId)
    }
  }, [storeId])

  const onChange = (e: any) => {
    setStoreInput(e.target.value)
  }

  if (redirect) {
    return <Redirect to="/" />
  }

  return (
    <Page title="Login" className={css.root}>
      <Paper className={css.login}>
        <Typography variant="h4" className={css.title}>
          Store
        </Typography>
        <TextField
          type="password"
          id="order-input"
          variant="outlined"
          fullWidth
          placeholder="Enter store ID (case-sensitive)"
          value={storeInput}
          onChange={onChange}
          InputProps={{
            autoFocus: true,
          }}
          className={css.input}
        />
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          disabled={storeInput === '' || storeInput.length < 3}
        >
          Launch
        </Button>
      </Paper>
    </Page>
  )
}

export default Auth
