import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'

const Page = (props: any) => {
  const { title, children, location, staticContext, ...rest } = props

  return (
    <div {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
}

export default withRouter(Page)
