const groups = {
  walkin: {
    id: 'walkin',
    name: 'Takeaway',
    color: '#3e5477',
    lastChange: null,
    sequenceId: true,
  },
  grabfood: {
    id: 'grabfood',
    name: 'Grabfood',
    color: '#00b140',
    lastChange: null,
    sequenceId: false,
  },
  foodpanda: {
    id: 'foodpanda',
    name: 'Foodpanda',
    color: '#C60D5C',
    lastChange: null,
    sequenceId: true,
  },
  deliveroo: {
    id: 'deliveroo',
    name: 'Deliveroo',
    color: '#00c6b6',
    lastChange: null,
    sequenceId: false,
  },
}
export default groups
