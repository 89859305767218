import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import Dexie from 'dexie'

import { Page, DexieCtx } from 'components'

const Reset = () => {
  const db: any = useContext(DexieCtx)
  const [done, setDone] = useState(false)
  const [error, setError] = useState(null)

  Dexie.delete(db.name)
    .then(() => {
      setDone(true)
    })
    .catch((err) => {
      setError(err)
    })
  if (done) {
    console.log('DB Reset')
    return <Redirect to="/" />
  }
  return (
    <Page title="Reset">
      <p>Uh oh, there was an issue:</p>
      <p>{error}</p>
    </Page>
  )
}

export default Reset
