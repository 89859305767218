import React, { useRef, useEffect, useContext } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import { RouteComponentProps } from 'react-router-dom'
import { makeAutoObservable } from 'mobx'
import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import clsx from 'clsx'
import { getUnixTime } from 'date-fns'
import { usePresenceChannel } from '@harelpls/use-pusher'
import { useDisplayService } from 'service/display'
import localStorage from 'store2'
import { useQueryParam, StringParam } from 'use-query-params'
import { isIOS } from 'react-device-detect'
import { useSnackbar } from 'notistack'
import { useTime } from 'react-timer-hook'
import { collection, query, onSnapshot } from 'firebase/firestore'

import {
  ButtonBase,
  IconButton,
  InputAdornment,
  InputBase,
  Link,
  LinearProgress,
  Menu,
  MenuItem,
  Paper,
  Typography,
  TextField,
} from '@material-ui/core'
import { makeStyles, createStyles } from '@material-ui/styles'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import DoneOutlineIcon from '@material-ui/icons/DoneOutline'
import TimerIcon from '@material-ui/icons/Timer'
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone'
import { Transition } from 'react-transition-group'

import { Page, AppStoreCtx, DexieCtx, useHub } from 'components'
import { ISlot } from 'AppDB'
import defaultGroups from '../../groups'
import { useFirestore } from 'hooks/useFirebase'
// import useKeyPress from 'utils/useKeyPress'

// import debug from 'debug'
// const log = debug('@ikew:views:Rail')

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 0,
      height: '100vh',
    },
    railWrap: {
      padding: theme.spacing(2),
      display: 'flex',
      height: '100%',
    },
    menu: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(2),
    },

    headerWrap: {},
    header: {
      padding: theme.spacing(1, 4),
      background: '#30313c',
      '& *': {
        color: 'white',
      },
    },
    waitTime: {
      margin: theme.spacing(0, 1),
      fontSize: '1.1em',
    },

    groupLegend: {
      display: 'flex',
      justifyContent: 'center',
      background: '#30313c',
    },
    groupBtn: (group?: any) => ({
      '& button': {
        width: '100%',
      },
      '& h4': {
        backgroundColor: group.color,
        padding: theme.spacing(1, 3.5),
        display: 'block',
        color: 'white',
        textAlign: 'center',
        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
      },
    }),
    slotColor: (group?: any) => ({
      backgroundColor: group.color,
    }),
    rail: {
      display: 'flex',
    },
    groupType: {
      width: '50%',
      padding: theme.spacing(1.5, 2, 2, 2),
    },
    ready: {
      '& h3': {
        color: '#397b0c',
      },
    },
    inProgress: {
      marginRight: '5px',
      borderRight: '2px solid #cecece',
      '& h3': {
        color: '#1773aa;',
      },
      '@media (orientation: landscape)': {
        width: '75%',
      },
    },
    groupSlots: {
      display: 'flex',
      flexFlow: 'wrap',
      padding: theme.spacing(2, 0),
    },
    groupInProgress: {},
    groupReady: {},
    groupHeader: {
      width: '100%',
      marginBottom: theme.spacing(1),
    },
    groupTitle: {
      display: 'flex',
      fontSize: '28px',
    },
    groupIcon: {
      marginRight: theme.spacing(0.5),
    },

    // Slot
    slot: {
      padding: '0.2em',
      textAlign: 'center',
      minWidth: '10%',
    },
    inProgressSlot: {},
    readySlot: {
      minWidth: '15%',
    },
    slotBtn: {
      padding: theme.spacing(3.5, 3),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    slotGroup: {
      marginBottom: theme.spacing(1.7),
      color: 'white',
      fontWeight: 500,
      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
      lineHeight: 1.1,
      width: '100%',
    },
    label: {
      fontSize: '3.5em',
      textAlign: 'center',
      fontWeight: 500,
      color: 'white',
      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.3)',
    },

    // Footer
    slotInputWrap: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    slotInput: {
      marginLeft: theme.spacing(1),
      padding: theme.spacing(1),
      textAlign: 'center',
    },

    blink: {
      animation: '$blink-1 1s both',
      animationIterationCount: 'infinite',
    },
    '@keyframes blink-1': {
      '0%': { opacity: 1 },
      '50%': { opacity: 1 },
      '100%': { opacity: 1 },
      '25%': { opacity: 0.5 },
      '75%': { opacity: 0.5 },
    },

    connectError: {
      color: 'red',
      padding: theme.spacing(4),
    },
  }),
)

interface RailProps extends RouteComponentProps<any> {
  initialState?: any
}

export const Rail = (props: RailProps) => {
  const { initialState = {} } = props
  const css = useStyles()
  const hub = useHub()
  const history = useHistory()

  const groups: any = useLocalObservable(() => ({}))

  const appStore: any = useContext(AppStoreCtx)
  const state: any = useLocalObservable(() => ({
    error: null,

    storeId: appStore.storeId,
    store: {},

    selectedGroup: null,
    expiryQueue: [],
    waitTime: '',
    changes: 0,
    update() {
      state.changes += 1
    },

    slots: [],

    ...initialState,
  }))

  useEffect(() => {
    async function getStore() {
      if (!state.storeId) {
        return
      }
      try {
        const response = await hub.getStore()
        const { data: store } = response
        state.store = store
        state.waitTime = `${store.waitTime} mins`

        if (store.groups) {
          const storeGroups = buildGroupsStore(store.groups)
          for (const [key, group] of Object.entries(storeGroups)) {
            groups[key] = group
          }
        } else {
          for (const [key, group] of Object.entries(defaultGroups)) {
            groups[key] = group
          }
        }
      } catch (err) {
        const { response } = err
        if (!response) {
          state.error = true
        }
        if (response && response.status === 404) {
          history.replace('/')
        }
      }
    }
    getStore()
  }, [history, hub, groups, state, state.error, state.store, state.storeId, state.waitTime])

  // const press1 = useKeyPress('1')
  // const press2 = useKeyPress('2')
  // const press3 = useKeyPress('3')
  // const press4 = useKeyPress('4')
  // useEffect(() => {
  //   log('Key press trigger')
  //   if (!state.selectedGroup) {
  //     if (press1) {
  //       state.selectedGroup = groups.grabfood
  //     }
  //     if (press2) {
  //       state.selectedGroup = groups.foodpanda
  //     }
  //     if (press3) {
  //       state.selectedGroup = groups.deliveroo
  //     }
  //     if (press4) {
  //       state.selectedGroup = groups.walkin
  //     }
  //   }
  // }, [
  //   groups.deliveroo,
  //   groups.foodpanda,
  //   groups.grabfood,
  //   groups.walkin,
  //   press1,
  //   press2,
  //   press3,
  //   press4,
  //   state.selectedGroup,
  // ])

  // handle setting store id via query string
  const [storeParam] = useQueryParam('store', StringParam)
  if (storeParam) {
    localStorage.set('storeId', storeParam, true)
    // strip the query string
    window.location.href = window.location.pathname
  }

  const onChangeWaitTime = (event: any) => {
    state.waitTime = event.target.value
  }

  if (!state.storeId) {
    return <Redirect to="/set-store" />
  }

  return (
    <Page className={css.root} title="Queue Display">
      <Observer>
        {() => (
          <>
            {state.error && (
              <Typography variant="h1" className={css.connectError}>
                Error getting store information. Refresh or contact support
              </Typography>
            )}
            {!state.error && (
              <>
                {state.storeId && state.store.remote && (
                  <CommandListeners state={state} groups={groups} />
                )}

                <Slots state={state} />

                <RailActions />

                <div className={css.header}>
                  <Typography variant="h1">
                    Waiting Time:
                    <InputBase
                      classes={{
                        root: css.waitTime,
                      }}
                      value={state.waitTime}
                      onChange={onChangeWaitTime}
                      inputProps={{ 'aria-label': 'naked' }}
                      data-testid="waitTime"
                    />
                  </Typography>
                  {state.store && state.store.headerMessage && (
                    <Typography variant="h2" data-testid="headerMessage">
                      {state.store.headerMessage}
                    </Typography>
                  )}
                </div>

                {(!state.storeId || !state.store.remote) && (
                  <div className={css.groupLegend}>
                    {Object.entries(groups).map(([key, group]) => (
                      <GroupLegend key={key} group={group} state={state} />
                    ))}
                  </div>
                )}

                <div className={css.railWrap}>
                  <Preparing state={state} groups={groups} />
                  <Ready state={state} groups={groups} />
                </div>

                <div className={css.slotInputWrap}>
                  {state.selectedGroup && <SlotInput groups={groups} state={state} />}
                </div>
              </>
            )}
            {process.env.NODE_ENV === 'development' && <pre>{JSON.stringify(state, null, 2)}</pre>}
          </>
        )}
      </Observer>
    </Page>
  )
}

const Slots = observer((props: any) => {
  const { state } = props
  const displayService = useDisplayService()

  useEffect(() => {
    async function getSlots() {
      const slots = await displayService.activeSlots()
      const results: UISlot[] = []
      slots.forEach((slot: ISlot) => {
        results.push(new UISlot(slot))
      })
      state.slots = results
    }
    if (!state.store.remote) {
      getSlots()
    }
    // eslint-disable-next-line
  }, [displayService, state.changes])

  return null
})

const CommandListeners = (props: any) => {
  const { state } = props
  const { storeId } = state

  usePresenceChannel(`presence-${storeId}`)
  const { db } = useFirestore()

  useEffect(() => {
    if (!db) {
      return
    }

    const handleState = (data: any) => {
      const { waitTime } = data
      state.waitTime = waitTime ? `${waitTime} mins` : `${state.store.waitTime} mins`
    }

    const q = query(collection(db, storeId))
    onSnapshot(q, (querySnapshot) => {
      const slots: UISlot[] = []
      querySnapshot.forEach((doc) => {
        if (doc.id === '_state') {
          handleState(doc.data())
          return
        }
        const data = doc.data()
        const uiSlot = new UISlot({
          ...data,
          id: doc.id,
          ready: data.ready ? 1 : 0,
          cleared: 0,
        })
        slots.push(uiSlot)
      })
      state.slots = [...slots]
    })
    // eslint-disable-next-line
  }, [db, storeId])

  return null
}

const RailActions = () => {
  const db: any = useContext(DexieCtx)
  const css = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const purge = async () => {
    await db.slots.toCollection().delete()
    window.location.reload()
  }

  return (
    <div className={css.menu}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem key="purge" onClick={() => purge() && handleClose()}>
          Clear all
        </MenuItem>
      </Menu>
    </div>
  )
}

const GroupLegend = observer((props: any) => {
  const { group, state } = props
  const css: any = useStyles(group)

  const onClickHeader = () => {
    state.selectedGroup = group
  }

  return (
    <Observer>
      {() => (
        <div className={clsx(css.groupBtn)}>
          <Link component="button" underline="none" onClick={onClickHeader}>
            <Typography variant="h4">{group.name}</Typography>
          </Link>
        </div>
      )}
    </Observer>
  )
})

const Ready = observer((props: any) => {
  const { state, groups } = props
  const css = useStyles()
  const displayService = useDisplayService()

  const onClick = async (uis: UISlot) => {
    const { slot } = uis
    if (!slot.id) {
      return
    }
    uis.isHidden = true
    displayService
      .setCleared(slot.id)
      .then(() => {
        state.slots = state.slots.filter((o: UISlot) => o.slot.id !== slot.id)
      })
      .catch(() => {
        uis.isHidden = false
        uis.error = true
      })
  }

  const slots = state.slots.filter((o: UISlot) => o.ready)
  if (slots.length > 0) {
    slots.sort((a: UISlot, b: UISlot) =>
      a.slot.readyDate && b.slot.readyDate ? b.slot.readyDate - a.slot.readyDate : 1,
    )
  }

  return (
    <div className={clsx(css.groupType, css.ready)}>
      <Typography variant="h3" className={css.groupTitle}>
        <DoneOutlineIcon className={css.groupIcon} /> Ready
      </Typography>
      <div className={clsx(css.groupSlots, css.groupReady)}>
        {slots && slots.length > 0 ? (
          slots.map((ro: any) => (
            <Slot key={ro.slot.id || ro.slot.created} slot={ro} onClick={onClick} groups={groups} />
          ))
        ) : (
          <Typography variant="h4">Nothing ready</Typography>
        )}
      </div>
    </div>
  )
})

// Group slots according to group slot
const groupSlots = (slots: UISlot[]): UISlot[] => {
  const s = [...slots]
  s.sort((a, b) => {
    if (a.slot.group !== b.slot.group) {
      return 1
    }
    return 0
  })
  return s
}

const Preparing = observer((props: any) => {
  const { state, groups } = props
  const css = useStyles()
  const displayService = useDisplayService()

  const onClick = async (uis: UISlot) => {
    const { slot } = uis
    if (!slot.id) {
      return
    }
    uis.slot.ready = 1
    uis.slot.readyDate = getUnixTime(new Date())
    displayService.setReady(slot.id).catch(() => {
      uis.isHidden = false
      uis.error = true
    })
  }

  const slots = groupSlots(state.slots.filter((o: UISlot) => o.preparing))

  return (
    <div className={clsx(css.groupType, css.inProgress)}>
      <Typography variant="h3" className={css.groupTitle}>
        <TimerIcon className={css.groupIcon} />
        Preparing
      </Typography>
      <div className={clsx(css.groupSlots, css.groupInProgress)}>
        {slots && slots.length > 0 ? (
          slots.map((po: UISlot) => (
            <Slot key={po.slot.id || po.slot.created} slot={po} onClick={onClick} groups={groups} />
          ))
        ) : (
          <Typography variant="h4">Nothing in progress</Typography>
        )}
      </div>
    </div>
  )
})

export const Slot = observer((props: any) => {
  const { slot: uiSlot, groups, onClick, className, ...rest } = props
  const { slot, pending, error } = uiSlot

  const group = groups[slot.group]
  const css: any = useStyles(group)
  useTime()

  const pfName = group ? group.name.toUpperCase() : null
  const slotType = slot.ready === 1 ? 'ready' : 'inProgress'

  // styles
  const slotStyles = {
    [css.slot]: true,
    [css[`${slotType}Slot`]]: true,
  }
  const slotColorStyles = {
    [css.slotColor]: true,
  }

  // transitions
  const transitionStyles: any = {
    entering: css.blink,
    entered: css.blink,
  }
  let transitionIn = false
  const now = getUnixTime(new Date())
  if (uiSlot.ready && uiSlot.slot.readyDate >= now - 3) {
    transitionIn = true
  }

  // handlers
  const handleClick = () => {
    onClick(uiSlot)
  }

  if (!uiSlot || uiSlot.isHidden) {
    return null
  }

  return (
    <Transition in={transitionIn} appear={true} timeout={0}>
      {(transitionState) => {
        if (transitionStyles) {
          slotStyles[transitionStyles[transitionState]] = true
        }
        return (
          <div className={clsx(slotStyles)}>
            <Paper {...rest} className={clsx(slotColorStyles)} elevation={0}>
              {pending && <LinearProgress variant="indeterminate" className={css.slotPending} />}
              <ButtonBase className={css.slotBtn} onClick={handleClick}>
                <Typography variant="subtitle1" className={css.slotGroup}>
                  {pfName}
                </Typography>
                <Typography className={css.label} data-testid={`label-${slotType}`}>
                  {slot.label}
                  {error && <ErrorTwoToneIcon className={css.slotError} />}
                </Typography>
              </ButtonBase>
            </Paper>
          </div>
        )
      }}
    </Transition>
  )
})

const SlotInput = observer((props: any) => {
  const { state } = props
  const { slots } = state
  const css: any = useStyles()
  const slotInput: any = useRef()
  const displayService = useDisplayService()
  const { enqueueSnackbar } = useSnackbar()

  const onCancel = () => {
    state.selectedGroup = null
  }
  const onSubmit = async (ev: any) => {
    ev.persist() // to allow shiftKey detection
    if (ev.key === 'Enter') {
      const label = slotInput.current.value
      const group = state.selectedGroup
      if (!label || !group) {
        return
      }

      const slot = {
        label,
        group: group.id,
        ready: 0,
        cleared: 0,
        created: getUnixTime(new Date()),
      }
      const n = slots.push(new UISlot(slot, true))
      slotInput.current.value = ''
      displayService
        .addInProgress({ label, group: group.id })
        .then((slot: ISlot) => {
          slots[n - 1] = new UISlot(slot, false)
          if (!ev.shiftKey) {
            onCancel()
          }
        })
        .catch(() => {
          slots.splice(n - 1, 1)
          const snackbarOpts: any = {
            variant: 'error',
            autoHideDuration: 1500,
          }
          enqueueSnackbar(`Error adding ${group.name}: ${label}`, snackbarOpts)
        })

      // sequence id
      const { sequenceId } = state.selectedGroup
      if (sequenceId) {
        const num = parseInt(label, 10)
        if (num) {
          state.selectedGroup.nextId = num + 1
        }
      }

      ev.preventDefault()
    }
  }
  const onFocus = (event: any) => {
    event.target.select()
  }
  const startAdornment = state.selectedGroup ? (
    <InputAdornment position="start">{state.selectedGroup.id}</InputAdornment>
  ) : null

  // set sequence ids
  const addProps: any = {}
  const { sequenceId, nextId } = state.selectedGroup
  if (sequenceId && !!nextId) {
    addProps.defaultValue = nextId
  }

  return (
    <Paper className={css.slotInput}>
      <TextField
        inputRef={slotInput}
        id="slot-input"
        fullWidth
        onKeyPress={onSubmit}
        onBlur={onCancel}
        onFocus={onFocus}
        InputProps={{
          autoFocus: true,
          type: isIOS ? 'number' : 'text',
          startAdornment,
        }}
        {...addProps}
      />
    </Paper>
  )
})

const buildGroupsStore = (data: Group[]) => {
  const store: { [x: string]: Group } = {}
  data.forEach((group) => {
    store[group.id] = group
  })
  return store
}

export class UISlot {
  slot: any
  pending: boolean
  error: boolean
  isHidden: boolean

  constructor(slot: any, pending: boolean = false, error: boolean = false) {
    makeAutoObservable(this)
    this.slot = slot
    this.pending = pending
    this.error = error
    this.isHidden = false
  }

  get ready() {
    if (this.slot.hasOwnProperty('state')) {
      return this.slot.state === 2
    }
    return this.slot.ready === 1 && this.slot.cleared === 0
  }

  get preparing() {
    if (this.slot.hasOwnProperty('state')) {
      return this.slot.state === 1
    }
    return this.slot.ready === 0 && this.slot.cleared === 0
  }
}

export interface Group {
  id: string
  name: string
  color: string
  sequenceId: boolean
  [x: string]: any
}

export default Rail
