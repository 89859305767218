import React from 'react'
import AppDB from '../AppDB'

// import debug from 'debug'
// const log = debug('@ikew:components:DexieProvider')

export const DexieCtx = React.createContext(null)

export const DexieProvider = (props: any) => {
  const { children } = props
  const db: any = new AppDB()
  return <DexieCtx.Provider value={db}>{children}</DexieCtx.Provider>
}
