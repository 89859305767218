import palette from '../palette'

const MuiInputBase = {
  root: {},
  input: {
    '&::placeholder': {
      opacity: 1,
      color: palette.text.secondary,
    },
  },
}
export default MuiInputBase
