import Dexie from 'dexie'

class AppDB extends Dexie {
  name: string
  slots: Dexie.Table<ISlot, number>

  constructor() {
    const name = 'ikew'
    super(name)

    this.name = name

    this.version(1).stores({
      orders: '++id, [platform+orderId], [ready+cleared], ready, cleared, readyDate',
    })

    this.version(2).stores({
      orders: null,
      slots: '++id, [group+label], [ready+cleared], ready, cleared, readyDate',
    })

    this.slots = this.table('slots')
  }
}

export interface ISlot {
  id?: number
  group: string
  label: string
  ready: number // boolean indexing
  cleared: number // boolean indexing
  created: number
  readyDate?: number
}

export default AppDB
