import palette from '../palette'

const MuiIconButton = {
  root: {
    color: palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)',
    },
  },
}

export default MuiIconButton
