import React, { useContext, useMemo } from 'react'
import HubService from 'service/hub'
import { AppStoreCtx } from 'components'

export const HubContext: any = React.createContext(null)

export const HubProvider = (props: any) => {
  const { children } = props
  const appStore: any = useContext(AppStoreCtx)
  const service: any = useMemo(() => new HubService(appStore.storeId), [appStore.storeId])
  return <HubContext.Provider value={service}>{children}</HubContext.Provider>
}

export const useHub = () => {
  const service: HubService = useContext(HubContext)
  return service
}
