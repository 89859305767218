import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { QueryParamProvider } from 'use-query-params'
import { SnackbarProvider } from 'notistack'
import { configure } from 'mobx'

import { theme } from './theme'
import { DexieProvider, AuthenticatedApp, HubProvider, AppStoreProvider } from 'components'
import { FirestoreProvider } from './hooks'
import SetStore from './views/SetStore'
import DisplayApp from './views/Rail'
import DbReset from './views/Rail/Reset'
import Error from './views/Error404.js'

// import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/main.scss'

configure({
  enforceActions: 'never',
})

function App() {
  return (
    <FirestoreProvider>
      <DexieProvider>
        <AppStoreProvider>
          <HubProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Router>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                      <Route exact component={SetStore} path="/set-store" />
                      <Route exact component={DbReset} path="/reset" />
                      <AuthenticatedApp exact component={DisplayApp} path="/" />
                      <Route component={Error} />
                    </Switch>
                  </QueryParamProvider>
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </HubProvider>
        </AppStoreProvider>
      </DexieProvider>
    </FirestoreProvider>
  )
}

export default App
