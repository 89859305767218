import { useContext, useMemo } from 'react'
import { getUnixTime } from 'date-fns'
import { DexieCtx } from 'components'
import { ISlot } from 'AppDB'

import debug from 'debug'
const log = debug('@ikew:DisplayService')

class DisplayService {
  db: any = null

  constructor(db: any) {
    this.db = db
  }

  activeSlots(): Promise<ISlot[]> {
    const { db } = this
    return db.slots.where('cleared').equals(0).toArray()
  }

  addInProgress(slot: IAddInProgress): Promise<ISlot> {
    log(`Add In Progress: ${slot.label}`)
    return new Promise(async (resolve, reject) => {
      const { db } = this
      const now = getUnixTime(new Date())
      const inProgress = {
        ready: 0,
        cleared: 0,
        created: now,
        ...slot,
      }
      try {
        const createdId = await db.slots.add(inProgress)
        const slot = { id: createdId, ...inProgress }
        resolve(slot)
      } catch (err) {
        reject(err)
      }
    })
  }

  setReady(id: number): Promise<boolean> {
    log(`Set ready: ${id}`)
    return new Promise(async (resolve, reject) => {
      const { db } = this
      const now = getUnixTime(new Date())
      try {
        const updated = await db.slots.update(id, {
          ready: 1,
          readyDate: now,
        })
        resolve(updated)
      } catch (err) {
        reject(err)
      }
    })
  }

  setCleared(id: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db } = this
      try {
        const updated = await db.slots.update(id, {
          cleared: 1,
        })
        resolve(updated)
      } catch (err) {
        reject(err)
      }
    })
  }

  findSlot(slot: IFindSlot): Promise<ISlot> {
    return new Promise(async (resolve, reject) => {
      const { db } = this
      const { group, label } = slot
      try {
        const [found]: ISlot[] = await db.slots
          .where({ group })
          .filter((o: any) => o.label === label)
          .toArray()
        if (found) {
          resolve(found)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  clearReady(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db } = this
      try {
        await db.slots.where(['ready', 'cleared']).equals([1, 0]).modify({
          cleared: true,
        })
        resolve(true)
      } catch (err) {
        reject(false)
      }
    })
  }

  removePreparing(): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { db } = this
      try {
        await db.slots.where(['ready', 'cleared']).equals([0, 0]).delete()
        resolve(true)
      } catch (err) {
        reject(false)
      }
    })
  }
}

interface IAddInProgress {
  group: string
  label: string
}

interface IFindSlot {
  group: string
  label: string
}

export const useDisplayService = () => {
  const db: any = useContext(DexieCtx)
  const service = useMemo(() => new DisplayService(db), [db])
  return service
}
